import { Environments } from './types';

const environments: Environments = {
  appEnvironment: import.meta.env.VITE_APP_ENVIRONMENT,
  baseName: import.meta.env.VITE_APP_BASE_NAME,
  bypassAuthorization: import.meta.env.VITE_APP_BYPASS_AUTHORIZATION,
  oktaUrl: import.meta.env.VITE_APP_OKTA_URL,
  oktaAppClientId: import.meta.env.VITE_APP_OKTA_CLIENT_ID,
  oktaRedirectUrl: import.meta.env.VITE_APP_OKTA_REDIRECT_URL,
  oktaPostLogoutUrl: import.meta.env.VITE_APP_OKTA_POST_LOGOUT_URL,
  isMockApi: import.meta.env.VITE_APP_MOCK_APIS === 'true',
  apiUrl: import.meta.env.VITE_APP_API_DOMAIN,
  disableWithCredentials:
    import.meta.env.VITE_APP_DISABLE_WITH_CREDENTIALS === 'true',
  logoutIdleTimeInMinutes: import.meta.env.VITE_APP_LOGOUT_IDLE_TIME_MIN,
  captchaApiKey: import.meta.env.VITE_CAPTCHA_API_KEY,
  captchaScriptUrl: import.meta.env.VITE_CAPTCHA_INTEGRATION_URL,
  useAwsWafCaptcha: import.meta.env.VITE_USE_AWS_WAF_CAPTCHA === 'true',
  apiGateWayId: import.meta.env.VITE_API_GATEWAY_ID,
  disableDevTools: import.meta.env.VITE_DISABLE_DEVTOOLS === 'true',
};

export default environments;
