import { TableRow } from '@snsw/react-component-library';
import { Accordion } from '@snsw/react-component-library/build/Components';
import { colours, pxToRem, spacing } from 'ams-common';
import styled from 'styled-components';

import { DocumentStatusType } from './types';

export const StyledAccordion = styled(Accordion)`
  section {
    padding: 0;
  }
`;

export const StyledTableRow = styled(TableRow)<{
  isRead?: boolean;
}>`
  font-weight: ${(props) => (props.isRead ? 'normal' : 'bold')};
`;

export const ActionCell = styled.div`
  position: relative;
  cursor: pointer;

  > button {
    all: unset;
  }
`;

export const StyledCell = styled.div`
  display: flex;
  align-items: baseline;

  .checkbox-item {
    margin-top: 0;
  }

  > svg {
    margin-right: ${spacing.sm};
  }
`;

export const Indicator = styled.div<{
  isRead?: boolean;
}>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: ${spacing.sm};
  background-color: ${colours.backgrounds.default};

  ${({ isRead }) =>
    !isRead &&
    `
  background-color: ${colours.primary.light}
`}
`;

export const Status = styled.div<{
  status: DocumentStatusType;
}>`
  color: ${({ status }) => {
    switch (status) {
      case 'Sent':
        return `${colours.warning.base}`;
      case 'Received':
        return `${colours.success.base}`;
      case 'Draft':
        return `${colours.text.link}`;
      default:
        return `${colours.neutral.dark}`;
    }
  }};
`;

export const StyledSpan = styled.span<{
  isDocumentNameColumn: boolean;
}>`
  width: ${(props) => (props.isDocumentNameColumn ? pxToRem(400) : 'auto')};
`;

export const StyledHeaderCell = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.sm};
`;

export const StyledDescriptionPopup = styled.div`
  display: flex;
  width: 250px;
  white-space: normal;
  text-align: left;
  padding: 10px;
`;
