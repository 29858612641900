export enum DocumentFieldLabels {
  DocumentName = 'Document name',
  Client = 'Customer',
  Status = 'Status',
  UploadedDate = 'Uploaded date',
  UploadedBy = 'Uploaded by',
  Classification = 'Classification',
  Description = 'Description',
  Recipient = 'Recipient',
  DueDate = 'Due date',
  Message = 'Message',
}

export enum DocumentFieldNames {
  DocumentName = 'documentName',
  DocumentUrl = 'documentUrl',
  ClientId = 'clientId',
  ClientName = 'clientName',
  Status = 'status',
  DisplayStatus = 'displayStatus',
  UploadedDate = 'uploadedDate',
  UploadedBy = 'uploadedBy',
  Classification = 'classification',
  Description = 'description',
  Recipient = 'recipient',
  DueDate = 'dueDate',
  Message = 'message',
  IsSelected = 'isSelected',
  IsRead = 'isRead',
  IsAuditorRead = 'isAuditorRead',
  IsClientRead = 'isClientRead',
  Action = 'action',
  DocumentId = 'id',
  DocumentS3Key = 's3Key',
}

export const DISPLAY_STATUS_MAPPER = {
  DRAFT: 'Draft',
  SENT: 'Sent',
  RECEIVED: 'Received',
  CLOSED: 'Closed',
};
