const contents = {
  'matters.correspondence.submitResponseToAuditCommencementDocuments.audit-questionnaire.tooltip.text':
    'This refers to the audit questionnaire template shared through the audit commencement documents. Please fill all the applicable fields and attach the filled up questionnaire in this section.',
  'matters.correspondence.submitResponseToAuditCommencementDocuments.financial-statements.tooltip.text':
    'If the financial statements include multiple accounting periods (comparative statements), then please only provide these for each second year. If financial statements have not been prepared yet for a year, then please provide trial balances.',
  'matters.correspondence.submitResponseToAuditCommencementDocuments.payroll-records.tooltip.text':
    'Please provide payroll and superannuation reports generated from the payroll system for each applicable financial year ending 30 June. Where applicable, please generate the reports to show the amounts paid in each state. Please do not provide copies of your annual payroll tax returns.',
  'matters.correspondence.submitResponseToAuditCommencementDocuments.business-group-structure.tooltip.text':
    'If applicable, please include details of the ultimate holding company (even if this company is located overseas). You can provide a copy of the stamped trust deed rather than the original.',
  'matters.correspondence.submitResponseToAuditCommencementDocuments.fringe-benefits-tax.tooltip.text':
    'Where applicable, provide working papers showing any breakup or apportionment of the FBT value between each state.',
  'matters.correspondence.submitResponseToAuditCommencementDocuments.exempt-payments-wages.tooltip.text':
    'Examples include maternity/paternity/adoption wages, Commonwealth paid parental leave and workers compensation payments.',
  'matters.correspondence.submitResponseToAuditCommencementDocuments.payments-outside-payroll-system.tooltip.text':
    'These include payments made to/by other entities and third parties for services provided to the business. Common examples include director/management/consulting fees and also payments to expatriate workers.',
  'matters.correspondence.submitResponseToAuditCommencementDocuments.taxable-payments-annual-report.tooltip.text':
    'Businesses in certain industries and government agencies may need to lodge a TPAR for the amounts paid to contractors for their services. Visit the ATO website for more information.',
  'matters.correspondence.submitResponseToAuditCommencementDocuments.shares-options-schemes.tooltip.text':
    'This can include a schedule of the shares/options granted, plan documents, letters of offer, ESS annual reports etc.',
  'matters.correspondence.submitResponseToAuditCommencementDocuments.jobkeeper-payments.tooltip.text':
    'Refer to the 2020 Annual Reconciliation page for more details on how Jobkeeper payments are treated for payroll tax.',
  'matters.correspondence.submitResponseToAuditCommencementDocuments.other-documents.tooltip.text':
    'Please attach any document that is determined outside of the above classifications. Note : The description is mandatory for all the documents attached to this section.',
  'matters.correspondence.submitResponseToAuditCommencementDocuments.contractor-information.tooltip.text':
    'Please review the payments you have made to contractors during the financial years as per the audit scope and provide the information for each in this section.',
  'matters.correspondence.submitResponseToAuditCommencementDocuments.apprentices-trainees-information.tooltip.text':
    'Please review the wages you have paid to any apprentices and trainees employed during the financial years as per the audit scope and provide the information in this section.',
  'matters.correspondence.submitResponseToAuditCommencementDocuments.uploadDocuments.heading':
    'Upload documents',
  'matters.correspondence.submitResponseToAuditCommencementDocuments.error.alert.title':
    'Response has no uploaded documents',
  'matters.correspondence.submitResponseToAuditCommencementDocuments.error.alert.text':
    ' You have not uploaded any documents, please review your response and upload any required documents.',
  'matters.correspondence.submitResponseToAuditCommencementDocuments.documents.authorised.contact.info':
    'Please be aware that all authorised contacts in this audit matter can view ALL portal content and documents',
};

export default contents;
