export const FILE_UPLOAD_FAILED =
  'Unable to upload the file at this moment. Please try again after few minutes.';
export const DOCUMENT_DETAILS_UPDATE_FAILED =
  'Unable to edit the file details at this moment. Please try again after few minutes.';
export const FILE_VIRUS_DETECTION_ERROR =
  'The file cannot be uploaded. It is possible that virus is detected in the file. Please rectify and upload.';
export const FILE_VIRUS_INTERIM_SERVER_ERROR =
  'Unable to upload the file at this moment. Please try again after few minutes. If the issue is persistent, please verify whether the file is password protected, which would not be supported by the system.';
export const EMPTY_FILE_ERROR =
  'The file cannot be uploaded as the file is empty. Please rectify and upload.';
