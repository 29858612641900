import { ReactNode } from 'react';
import * as t from 'io-ts';
import { tOptional } from 'src/types';
import { HandleEditDocument } from 'src/types/documentTypes';

import { MatterClientCodec } from '../clients/types';

import { DocumentFieldNames } from './constants';

export const MatterDocumentCodec = t.type({
  id: t.string,
  status: t.string,
  displayStatus: t.string,
  uploadedBy: t.string,
  uploadedDate: t.string,
  name: t.string,
  classification: t.string,
  description: t.string,
  readByAuditor: t.boolean,
  clients: t.array(MatterClientCodec),
  s3Key: tOptional(t.string),
});

export const MatterDocumentsCodec = t.array(MatterDocumentCodec);

export type MatterDocument = t.TypeOf<typeof MatterDocumentCodec>;

export type MatterDocuments = t.TypeOf<typeof MatterDocumentsCodec>;

export type ColumnType = {
  header: string;
  dataIndex: string;
  render?: ({
    columnData,
    rowData,
    rowIndex,
    documents,
    key,
    arrayHelper,
    handleDownloadDocument,
    handleDocumentEdit,
    handleDeleteDocument,
  }: {
    columnData?: string | number | null;
    rowData: { [key: string]: any };
    rowIndex?: number;
    documents: DocumentInitialValues; // Record<string, Array<{ [key: string]: any }>>;
    key: string;
    arrayHelper?: any;
    clientOptions?: { [key: string]: string };
    handleDownloadDocument?: (s3Key: string, documentName: string) => void;
    handleDocumentEdit?: (args: HandleEditDocument) => void;
    handleDeleteDocument?: (documentId: string, documentName: string) => void;
  }) => ReactNode | string | number;
};

export interface Props {
  columns: ColumnType[];
}

export const StatusCodec = t.union([
  t.literal('Received'),
  t.literal('Draft'),
  t.literal('Sent'),
]);

export type DocumentStatusType = t.TypeOf<typeof StatusCodec>;

export const FormattedMatterDocumentCodec = t.type({
  [DocumentFieldNames.DocumentId]: t.string,
  [DocumentFieldNames.DocumentName]: t.string,
  [DocumentFieldNames.DocumentUrl]: t.string,
  [DocumentFieldNames.ClientId]: t.string,
  [DocumentFieldNames.ClientName]: t.string,
  [DocumentFieldNames.Status]: t.union([
    t.literal('Received'),
    t.literal('Sent'),
    t.literal('Draft'),
  ]),
  [DocumentFieldNames.DisplayStatus]: t.string,
  [DocumentFieldNames.UploadedDate]: t.string,
  [DocumentFieldNames.UploadedBy]: t.string,
  [DocumentFieldNames.Classification]: t.string,
  [DocumentFieldNames.Description]: t.string,
  [DocumentFieldNames.IsRead]: t.boolean,
  [DocumentFieldNames.IsAuditorRead]: t.boolean,
  [DocumentFieldNames.IsClientRead]: t.boolean,
  [DocumentFieldNames.DocumentS3Key]: tOptional(t.string),
});

const ExtendedFormattedMatterDocumentCodec = t.intersection([
  FormattedMatterDocumentCodec,
  t.type({
    [DocumentFieldNames.IsSelected]: t.boolean,
  }),
]);

export const FormattedMatterDocumentResultsCodec = t.record(
  t.string,
  t.array(FormattedMatterDocumentCodec),
);

export const InitialValuesCodec = t.record(
  t.string,
  t.array(ExtendedFormattedMatterDocumentCodec),
);

export type DocumentInitialValues = t.TypeOf<typeof InitialValuesCodec>;
export type DocumentFormInitialValues = { documents?: DocumentInitialValues };

export const APPLICABLE_TO_ALL_CLIENTS = 'applicable-to-all-clients';
export const APPLICABLE_TO_ALL_CLIENTS_TEXT = 'Applicable to all customers';
