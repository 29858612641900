import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Col,
  ComponentLoader,
  ContentContainer,
  Heading,
  InPageAlert,
  Pagination,
  Row,
} from '@snsw/react-component-library';
import { QueryKey } from 'src/api/constants';
import ErrorHandler from 'src/components/ErrorHandler';
import { ErrorKey } from 'src/components/ErrorHandler/types';
import { ScreenNames } from 'src/constants';
import { useMatter } from 'src/context/MatterContext';
import { useMessagesData, useUserContext } from 'src/hooks';
import { MessagesContent, SortConfig, SortDirection } from 'src/hooks/types';
import { PATHS } from 'src/routes/constants';
import { UserLoginType } from 'src/types';

import { MessagesTable } from './MessagesTable';
import { StyledButton, StyledContainer } from './styles';

export const MatterMessages = () => {
  const { matterUId, matterId, isMatterClosed } = useMatter();
  const [errorKeys, setErrorKeys] = useState<ErrorKey[]>([]);
  const userContext = useUserContext();
  const isCustomer = userContext?.userType === UserLoginType.Customer;
  const { state } = useLocation();
  const { sentMessageSuccess } = state || {};

  useEffect(() => {
    if (sentMessageSuccess) {
      window.history.replaceState({}, '');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: 'lastActionDate',
    direction: SortDirection.desc,
  });
  const [page, setPage] = useState(1);

  const prevValues = useRef({ sortConfig, page });

  const {
    data: originalData,
    isLoading,
    refetch,
    isError,
    isRefetchError,
  } = useMessagesData(
    matterUId,
    isCustomer,
    10,
    page - 1,
    sortConfig.key,
    sortConfig.direction,
  );
  const [data, setData] = useState<MessagesContent | undefined>(
    originalData?.content || undefined,
  );

  const changeHandler = (pageNo: number) => {
    setPage(pageNo);
  };

  useEffect(() => {
    if (
      prevValues.current.sortConfig !== sortConfig ||
      prevValues.current.page !== page
    ) {
      const fetch = async () => {
        await refetch();
      };
      prevValues.current = { sortConfig, page };

      fetch();
    }
  }, [refetch, sortConfig, page]);

  useEffect(() => {
    if (originalData) setData(originalData?.content || undefined);
  }, [originalData]);

  useEffect(() => {
    if (isError || isRefetchError)
      setErrorKeys((prevErrorKeys) => [
        ...new Set([
          ...prevErrorKeys,
          `${ScreenNames.MESSAGES}-${QueryKey.MESSAGES}-GET` as ErrorKey,
        ]),
      ]);
  }, [isError, isRefetchError]);

  const sortData = (key: string) => {
    let direction: SortDirection;
    if (sortConfig.key === key && sortConfig.direction === SortDirection.desc) {
      direction = SortDirection.asc;
    } else direction = SortDirection.desc;

    if (sortConfig.key !== key) {
      direction = SortDirection.asc;
    }

    setSortConfig({ key, direction });
    setPage(1);
  };

  return (
    <ContentContainer>
      <ComponentLoader active={isLoading} fullPage />
      <StyledContainer>
        <Heading level={3}>Messages</Heading>
      </StyledContainer>
      <Row>
        <Col>
          {sentMessageSuccess ? (
            <InPageAlert variant="success" title="" compact>
              <p>
                <strong>{sentMessageSuccess}</strong>
              </p>
            </InPageAlert>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col>
          <Link
            to={`${PATHS.matters}/${matterId}${PATHS.messages}${PATHS.newMessage}`}
            style={{ textDecoration: 'none' }}
          >
            <StyledButton variant="primary" disabled={isMatterClosed}>
              New message
            </StyledButton>
          </Link>
        </Col>
      </Row>

      <ErrorHandler keys={errorKeys} />
      {data && data.length > 0 && !isError && (
        <>
          <MessagesTable data={data} sortData={sortData} matterId={matterId} />
          {originalData && originalData?.totalElements > 10 && (
            <Pagination
              totalItems={originalData?.totalElements}
              currentPage={page}
              pageSize={10}
              onPageChange={(pageNo) => {
                changeHandler(pageNo);
              }}
            />
          )}
        </>
      )}
    </ContentContainer>
  );
};
