import { useState } from 'react';
import { TableBody, TableCell, TableHead } from '@snsw/react-component-library';
import {
  StickyTable,
  StyledTableContainer,
  StyledTableHeader,
} from 'ams-common/src/styles';
import { FieldArray } from 'formik';
import { useMatter } from 'src/context/MatterContext';
import { useStickyTableColumns } from 'src/hooks';
import { HandleEditDocument } from 'src/types/documentTypes';

import DeleteDocument from '../deleteDocument';

import { columns } from './columns';
import { DocumentFieldNames } from './constants';
import { StyledHeaderCell, StyledSpan, StyledTableRow } from './styles';
import { DocumentInitialValues } from './types';

export const DocumentTable = ({
  dataField,
  documents,
  clientOptions,
  handleDownloadDocument,
  handleDocumentEdit,
  handleDeleteError,
}: {
  dataField: string;
  documents: DocumentInitialValues | undefined;
  clientOptions?: Record<string, string>;
  handleDownloadDocument: (documentId: string, documentName: string) => void;
  handleDocumentEdit: (args: HandleEditDocument) => void;
  handleDeleteError?: () => void;
}) => {
  const { matterUId } = useMatter();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [documentToDelete, setDocumentToDelete] = useState<{
    docId: string;
    docName: string;
  }>({ docId: '', docName: '' });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { offset } = useStickyTableColumns(dataField);

  const tableData = documents && documents[dataField];

  const closeDeleteDocumentModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteDocument = (documentId: string, documentName: string) => {
    setIsDeleteModalOpen(true);
    setDocumentToDelete({ docId: documentId, docName: documentName });
  };

  const tableColumns = columns();

  return documents ? (
    <FieldArray name="documents">
      {(arrayHelper) => (
        <StyledTableContainer id={dataField}>
          <StickyTable offset={offset} stickyColumns={1}>
            <TableHead>
              {tableColumns.map(({ header, dataIndex }) => (
                <StyledTableHeader key={header}>
                  <StyledHeaderCell>
                    <StyledSpan
                      isDocumentNameColumn={
                        dataIndex === DocumentFieldNames.DocumentName
                      }
                    >
                      {header}
                    </StyledSpan>
                  </StyledHeaderCell>
                </StyledTableHeader>
              ))}
            </TableHead>
            <TableBody>
              {tableData?.map((row, index) => (
                <StyledTableRow
                  key={row.id}
                  isRead={Boolean(row?.isAuditorRead)}
                >
                  {tableColumns?.map(({ render, dataIndex }) => (
                    <TableCell key={dataIndex}>
                      {render &&
                        dataIndex &&
                        render({
                          columnData: dataIndex ? row[dataIndex] : null,
                          rowData: row,
                          rowIndex: index,
                          documents,
                          key: dataField,
                          arrayHelper,
                          clientOptions,
                          ...(DocumentFieldNames.DocumentName === dataIndex ||
                          DocumentFieldNames.Action
                            ? { handleDownloadDocument }
                            : {}),
                          handleDocumentEdit,
                          handleDeleteDocument,
                        })}
                      {!render && dataIndex && row[dataIndex]}
                    </TableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </StickyTable>
          {isDeleteModalOpen && (
            <DeleteDocument
              matterUId={matterUId}
              documentId={documentToDelete.docId}
              documentName={documentToDelete.docName}
              close={closeDeleteDocumentModal}
              isOpen={isDeleteModalOpen}
              handleDeleteError={handleDeleteError}
            />
          )}
        </StyledTableContainer>
      )}
    </FieldArray>
  ) : null;
};
